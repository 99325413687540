import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, Columns, Container, Heading, Section } from 'react-bulma-components'
import Layout from '../components/Layout'


interface TrialPageTemplateProps {
  title: string;
  subtitle : string;
  content: string;
}

export const TrialPageTemplate = ({ title, subtitle, content }: TrialPageTemplateProps) => {


  return (
    <React.Fragment>
      <Section size="small">
        <Container>
          <Columns>
            <Columns.Column style={{textAlign: 'center'}}>
              <Heading>
                {title}
              </Heading>
                <Columns.Column style={{textAlign: 'center'}}>
                {subtitle}
                </Columns.Column>
              <Columns.Column>
              <a href="https://trial.model-broker.com/ " target="_blank">
                  <figure>
                    <img src={`/img/trialservicesteps.svg`}/>
                  </figure>
                </a>
              </Columns.Column>
            </Columns.Column>
          </Columns>
        </Container>
        <Columns.Column style={{textAlign: 'center'}}>
        <a href="https://trial.model-broker.com/ " target="_blank">
        <Button
           color={'primary'}
            rounded={true}
            //renderAs={Link}
            //to="/contact"
        >
             Access the Model Broker for Logic Diagrams trial service here! &nbsp;&nbsp;→
        </Button>
        </a>
        </Columns.Column>
        <Columns.Column style={{textAlign: 'center'}}>
        <a href="https://trial.model-broker.com/manual/ " target="_blank">
        <Button
           color={'primary'}
            rounded={true}
            //renderAs={Link}
            //to="/contact"
        >
             Access the Model Broker Turorial here! &nbsp;&nbsp;→
        </Button>
        </a>
        </Columns.Column>
        <Container>
          <Columns.Column style={{textAlign: 'center'}}>
           {content}
          </Columns.Column>
        </Container>
      </Section>
    </React.Fragment>
  )
}

const TrialPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout isIndex={false}>
      <TrialPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

TrialPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TrialPage

export const TrialPageQuery = graphql`
  query TrialPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        content
      }
    }
  }
`
